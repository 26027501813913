@import "src/styles/_variables.scss";
.ant-modal.project-modal {
  .ant-modal-header {
    text-align: center;
  }
  .ant-modal-content .ant-modal-body {
    max-height: 85vh;
    overflow-y: scroll;
    .project-main-info-container {
      display: flex;
      font-size: 16px;
      margin-bottom: 1rem;
      .project-data {
        margin-left: 1rem;
        .project-title {
          font-size: 24px;
          max-width: 40vw;
          word-break: break-word;
        }
        .project-price {
          font-weight: 600;
        }
      }
      .project-image {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        border-radius: 3.02px;
        height: 120px;
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-size: cover;
        .sdgs-container {
          margin: 0.3rem;
        }
      }
    }
    .project-SDGs {
      .project-SDG {
        display: flex;
        margin: 0.7rem;
        .SDG-image {
          height: 40px;
          min-width: 40px;
          margin-right: 0.5rem;
          background-size: contain;
        }
        .SDG-description {
          color: #777e8b;
        }
      }
    }
    .project-info-field {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      .field-value {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 400px;
      }
      a {
        color: black;
        text-decoration: underline;
      }
    }
    .ant-collapse-item > .ant-collapse-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    .project-gallery {
      .ant-collapse-content > .ant-collapse-content-box {
        display: flex;
        flex-wrap: wrap;
        .project-picture {
          background-size: cover;
          width: 200px;
          height: 150px;
          margin: 0.3rem;
        }
      }
    }
  }
}
.project-title {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  .project-price {
    min-width: 8rem;
    font-weight: normal;
  }
}
.project-kind {
  padding: 0.7rem 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  .elipsis {
    width: 13px;
    height: 13px;
    background: #008868;
    border-radius: 100%;
    margin-right: 0.5rem;
    border: 1.5px solid rgba(255, 255, 255, 0.5);
  }
}
.sdgs-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 65px;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2.46394px rgba(0, 0, 0, 0.08), 0px 4.10657px 8.21314px rgba(0, 0, 0, 0.04),
    0px 8.21314px 14.7837px rgba(0, 0, 0, 0.02), 0px 13.141px 24.6394px rgba(0, 0, 0, 0.04),
    0px 32.8526px 65.7051px rgba(0, 0, 0, 0.04);
  border-radius: 13.141px;
  font-size: 1rem;
}
@media only screen and (max-width: 680px) {
  .ant-modal.project-modal .ant-modal-content {
    .project-main-info-container {
      flex-direction: column;
      .project-data .project-title {
        font-size: initial;
        max-width: 80vw;
      }
    }
    .ant-modal-body .project-info-field .field-value {
      max-width: 200px;
    }
  }
}
