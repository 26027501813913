$_disabled_blue: #659fb2;
$_athens-gray: #fafafa;
$_soft_gray: #5a5e65;
$_white: #ffffff;
$_white-border: #d9d9d9;
$_dark: #021120;
$primary-color: #1c64f2;

$white-color: $_white;
$white-border-color: $_white-border;
$gray-light-color: $_athens-gray;

$dark-word-color: $_dark;
