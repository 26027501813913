@import url("src/styles/reset.css");
@import "src/styles/fonts.css";
@import "src/styles/customized_antd.scss";
@import "src/styles/project_modal.scss";
@import "src/styles/_tools_to_rem.scss";
@import "@climatetradecarbon/climatetrade-components/index.css";
@import "@climatetradecarbon/climatetrade-calculator/index.css";
@import "./_calculator.scss";

body {
  font-family: "Inter", sans-serif;
  background-color: white;
  background-size: cover;

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    .main-container {
      position: relative;
      display: flex;
      background: rgba(255, 255, 255);
      padding: 0 5vw;
    }
  }

  .offset-title {
    font-size: rem(40px);
    color: white;
    max-width: 55vw;
    font-weight: 900;
  }
}

.redsys-button-container {
  display: flex;
  justify-content: center;
  width: 100%;

  & > div {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 0.8em;
  border-radius: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 0.3em;
}

.customer-logo {
  height: 100px;
  width: 200px;
  background-position-x: left;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.description {
  font-size: 1rem;
  padding-top: 0.5rem;
  color: #40a9ff;
}

@media only screen and (max-width: 680px) {
  body #__next .main-container {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    div[class^='BaseButton_baseButton']{
      width: unset;
    }
  }
}

hr {
  min-height: unset;
}

.ModalComponent > div {
  max-width: 1080px;
  font-size: 16px;
}
