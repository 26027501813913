@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  min-height: 100vh;
  :global .embedded-layout-main-container {
    display: flex;
    flex-direction: column;
    width: 72vw;
    @media only screen and (max-width: 1000px) { &{
      overflow: hidden;
      width: 85vw;
    } }
    @media only screen and (max-width: 800px) { &{width: 95vw;} }
    min-height: 80vh;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    .embedded-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .embedded-header {
        padding: 1.2rem 0.2rem;
        display: flex;
        align-items: center;
        .embedded-title {
          font-weight: 700;
          font-family: "Inter";
          font-size: 24px;
          color: #003e6f;
        }
      }
      .settings-icon {
        cursor: pointer;
        right: 0;
      }
      .settings-container {
        position: relative;
      }
      .settings-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 4rem;
        z-index: 1;
        padding: 0.5rem;
        justify-content: space-around;
        width: 180px;
        height: 96px;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.08);
        /* Shadow */

        box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.08), 0px 16px 30px rgba(0, 0, 0, 0.08),
          0px 10px 18px rgba(0, 0, 0, 0.08), 0px 5px 10px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08),
          0px 1px 2px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
      }
    }
    .step-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .back {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 16px;
        width: 89px;
        height: 35px;
        background: rgba(170, 167, 167, 0.1);
        border-radius: 100px;
      }
      .title {
        font-size: rem(18px);
        font-weight: 600;
        width: 100%;
        text-align: center;
      }
    }
    .main-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem !important;
      .card-resume-container {
        position: relative;
        margin-top: unset;
        right: unset;
        margin-bottom: 11.5rem;
      }
      .personal-form {
        min-width: 45vw;
      }
    }
    .response-container {
      margin-top: unset;
      width: 800px;
    }
  }
}
@media only screen and (max-width: 680px) {
  .content {
    overflow-x: hidden;
    :global .embedded-layout-main-container {
      width: 90vw;
      min-height: unset;
      .embedded-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        .embedded-header {
          .embedded-title {
            font-size: 12px;
          }
        }
      }
      .main-container {
        .card-resume-container {
          margin-top: 3.4rem;
        }
      }
    }
  }
}
