.calculator {
  position: relative;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .CalculatorComponent {
    width: 100%;
    margin-right: 24px;

    @media only screen and (max-width: 768px) {
      order: 2;
      margin-right: 0;
    }
  }

  .ColumnComponent {
    min-height: 400px;
    min-width: 400px;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    .ResultComponent {
      width: 100%;

      @media only screen and (min-width: 768px) {
        min-width: 400px;
      }

      .ButtonComponent {
        margin-top: 24px;
        font-size: 14px;
        line-height: 24px;

        > i {
          padding: 0 8px;
        }
      }
    }

    .terms {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 14px;
      text-align: center;

      margin-bottom: 24px;
    }
  }

  // TODO: This style should be on components lib.
  .AccordionComponent {
    border: 1px solid rgba(0, 0, 0, 0.1);

    > span > div {
      height: 26px;
      width: 26px;
    }
  }
}
