@import "src/styles/_variables.scss";
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  color: $dark-word-color !important;
  background-color: #fff;
  border-color: $dark-word-color !important;
  &:hover {
    color: $dark-word-color;
  }
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: $dark-word-color !important;
  border-color: $dark-word-color !important;
  & svg {
    background: $dark-word-color;
    & path {
      color: white;
    }
  }
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-finish div.ant-steps-item-icon > span {
  background-color: $dark-word-color;
}

.ant-steps-item-container {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 16px;
  margin: 1rem 0;
  .ant-steps-item-tail {
    display: none !important;
  }
}
.ant-form-item-label label {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0 0.5rem;
}
.ant-modal-mask {
  backdrop-filter: blur(2px);
}
