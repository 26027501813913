@import "src/styles/_variables.scss";
@import "src/styles/_tools_to_rem.scss";
.header-container {
  width: 100%;
  z-index: 4;
  display: flex;
  background-color: $white-color;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw;
  :global .climate-logo {
    cursor: pointer;
  }

  :global .header-text {
    color: $gray-light-color;
  }
}

@media only screen and (max-width: 680px) {
  .header-container {
    height: fit-content;
    justify-content: space-between;
  }
}
